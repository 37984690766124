<style scoped lang="less">
	
	.edit-wraper{
		flex: 1 1 auto;
		overflow: auto;
		margin-bottom: 70px;
	}

	::-webkit-scrollbar {
		width: 0px;
	}

	::-webkit-scrollbar-track {
		background-color: none;
	}

	::-webkit-scrollbar-thumb {
		background-color: none;
	}

	::-webkit-scrollbar-thumb:hover {
		background-color: none;
	}

	::-webkit-scrollbar-thumb:active {
		background-color: none;
	}
</style>

<template>
	<div class="edit-wraper">
		<div v-for="(item, index) in list" :key="index">
			<normal-module v-if="item.moduleType===1" :data="item.data" :name="item.name" :visible="item.data.editVisible"></normal-module>
			<edit-picture v-if="item.moduleType===9" :data="item.data" :name="item.name" :visible="item.data.editVisible"></edit-picture>
			<edit-shop v-if="item.moduleType===2" :data="item.data" :name="item.name" :visible="item.data.editVisible"></edit-shop>
			<edit-search v-if="item.moduleType===16" :data="item.data" :name="item.name" :visible="item.data.editVisible"></edit-search>
			<hot-area @move="(record, index)=>{handelCanMove(record, index, item.uid)}" v-if="item.moduleType===12" :data="item.data" :name="item.name" :visible="item.data.editVisible"></hot-area>
			<point-shop v-if="item.moduleType===20" :data="item.data" :name="item.name" :visible="item.data.editVisible"></point-shop>
			<coupon-modal v-if="item.moduleType===18" :data="item.data" :name="item.name" :visible="item.data.editVisible"></coupon-modal>
			<notice-module v-if="item.moduleType===21" :data="item.data" :name="item.name" :visible="item.data.editVisible"></notice-module>
			<live-module v-if="item.moduleType===22" :data="item.data" :name="item.name" :visible="item.data.editVisible"></live-module>
			<guessYourLike v-if="item.moduleType===6" :data="item.data" :name="item.name" :visible="item.data.editVisible"></guessYourLike>
			<flash-sale v-if="item.moduleType===17" :data="item.data" :name="item.name" :visible="item.data.editVisible"></flash-sale>
			<new-product v-if="item.moduleType===23" :data="item.data" :name="item.name" :visible="item.data.editVisible"></new-product>
			<text-module v-if="item.moduleType===15" :data="item.data" :name="item.name" :visible="item.data.editVisible"></text-module>
			<wechat-group v-if="item.moduleType===24" :data="item.data" :name="item.name" :visible="item.data.editVisible"></wechat-group>
			<bind-group v-if="item.moduleType===25" :data="item.data" :name="item.name" :visible="item.data.editVisible"></bind-group>
			<spell-group v-if="item.moduleType===26" :data="item.data" :name="item.name" :visible="item.data.editVisible"></spell-group>
			<video-module v-if="item.moduleType===7" :data="item.data" :name="item.name" :visible="item.data.editVisible"></video-module>
			<help-activity v-if="item.moduleType===27" :data="item.data" :name="item.name" :visible="item.data.editVisible"></help-activity>
			<add-friends v-if="item.moduleType===28" :data="item.data" :name="item.name" :visible="item.data.editVisible"></add-friends>
			<channels-live v-if="item.moduleType===29" :data="item.data" :name="item.name" :visible="item.data.editVisible"></channels-live>
			<add-powder v-if="item.moduleType===30" :data="item.data" :name="item.name" :visible="item.data.editVisible"></add-powder>
			<inviting-sharer v-if="item.moduleType===31" :data="item.data" :name="item.name" :visible="item.data.editVisible"></inviting-sharer>
			<duoduo-shop v-if="item.moduleType===32" :data="item.data" :name="item.name" :visible="item.data.editVisible"></duoduo-shop>
			<duoduo-marketing v-if="item.moduleType===33" :data="item.data" :name="item.name" :visible="item.data.editVisible"></duoduo-marketing>
			<routuantuangou v-if="item.moduleType===34" :data="item.data" :name="item.name" :visible="item.data.editVisible"></routuantuangou>
		</div>
		
	</div>
</template>

<script>
	
	import normalModule from './browseModule/normalModule/edit';
	import editPicture from './browseModule/singleRowPicture/edit';
	import editShop from './browseModule/singleRowProduct/edit';
	import editSearch from './browseModule/searchModule/edit';
	import hotArea from './browseModule/hotArea/edit';
	import pointShop from './browseModule/pointShop/edit';
	import couponModal from './browseModule/couponModule/edit';
	import noticeModule from './browseModule/noticeModule/edit';
	import liveModule from './browseModule/liveModule/edit';
	import guessYourLike from './browseModule/guessYourLike/edit';
	import flashSale from './browseModule/flashSale/edit';
	import newProduct from './browseModule/newProduct/edit.vue';
	import textModule from './browseModule/textModule/edit.vue';
	import wechatGroup from './browseModule/wechatGroup/edit.vue';
	import bindGroup from './browseModule/bindGroup/edit.vue';
	import spellGroup from './browseModule/spellGroup/edit.vue';
	import videoModule from './browseModule/videoModule/edit.vue';
	import helpActivity from './browseModule/helpActivity/edit.vue';
	import addFriends from './browseModule/addFriends/edit.vue';
	import channelsLive from './browseModule/channelsLive/edit.vue';
	import addPowder from './browseModule/addPowder/edit.vue';
	import invitingSharer from './browseModule/invitingSharer/edit.vue';
	import duoduoShop from './browseModule/duoduoShop/edit.vue';
	import duoduoMarketing from './browseModule/duoduoMarketing/edit.vue';
	import routuantuangou from './browseModule/routuantuangou/edit.vue';

	export default {
		name: 'edit-box',
		components: {
			normalModule,
			editPicture,
			editShop,
			editSearch,
			hotArea,
			pointShop,
			couponModal,
			noticeModule,
			liveModule,
			guessYourLike,
			flashSale,
			newProduct,
			textModule,
			wechatGroup,
			bindGroup,
			spellGroup,
			videoModule,
			helpActivity,
			addFriends,
			channelsLive,
			addPowder,
			invitingSharer,
			duoduoShop,
			duoduoMarketing,
			routuantuangou
		},
		props: {
			list: {
				type: Array,
				default: () => {
					return []
				}
			}
		},
		watch:{
			list(){
				// console.log(this.list)
			}
		},
		data() {
			return {
				dataList: []
			};
		},
		methods: {
			handelCanMove(record, index, uid){
				this.$emit('move', record, index, uid);
			}
		},
		mounted() {
			// console.log(this.list)
		}
	};
</script>
