<style scoped lang="less">
	.menu-box {
		width: 280px;
		height: 100%;
		padding: 20px 15px 45px;
		overflow: auto;
		background: #fff;
		flex: 0 0 auto;
		margin-right: 50px;

		.menu-title {
			font-size: 14px;
			margin-bottom: 5px;
		}
		.menu-title1 {
			font-size: 12px;
			color: #999;
		}

		.item-box {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			list-style: none;
			margin-top: 30px;

			.item {
				width: 116px;
				margin-bottom: 10px;
				cursor: pointer;
				

				.img {
					width: 116px;
					height: 116px;
					overflow: hidden;
					border-radius: 3px;
					padding: 6px;
					box-sizing: border-box;
					background-color: #F7F8FA;

					img {
						display: block;
						width: 100%;
						height: 100%;
						pointer-events: none;
						border: 1px dashed #999;
					}
				}

				.name {
					font-size: 14px;
					color: #666;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
					text-align: center;
					margin-bottom: 10px;
				}
			}
			
			.item15{
				.img{
					img{
						width: 60%;
						margin: 5px auto 0;
					}
				}
			}
		}
	}
	
	::-webkit-scrollbar {
	width: 0px;
	}
	
	::-webkit-scrollbar-track {
	background-color: none;
	}
	
	::-webkit-scrollbar-thumb {
	background-color: none;
	}
	
	::-webkit-scrollbar-thumb:hover {
	background-color: none;
	}
	
	::-webkit-scrollbar-thumb:active {
	background-color: none;
	}
</style>

<template>
	<div class="menu-box">
		<div class="menu-item">
			<div class="menu-title">装修模块</div>
			<div class="menu-title1">点击装修模块，添加至中间页面装修预览区内</div>

			<div class="menu-list">
				<!-- <draggable class="item-box" :move="getdata" :end="end" @update="datadragEnd" :options="{animation: 300, handle:'.item', group: 'page'}"> -->
				<div class="item-box">
					<div class="item" @click="addModule(record)" v-for="(record, index) in menuList" :key="index" v-if=" (!isGroup && record.moduleType !== 25) || (isGroup && (record.moduleType == 25 && IsOpenCommunity) || record.moduleType !== 25) ">
						<div class="img">
							<img :src="record.icon" />
						</div>
						<p class="name">{{record.name}}</p>
					</div>
					<!--  </draggable> -->
				</div>
			</div>

		</div>
	</div>
</template>

<script>
	import draggable from 'vuedraggable';
	
	import {
		mapState,
		mapGetters
	} from 'vuex';

	export default {
		name: 'page-manage',
		components: {
			draggable
		},
		data() {
			return {
				menuList: [],
				isGroup: this.$route.query.isGroup === 'group'
			};
		},
		computed: {
			...mapState({
				IsOpenCommunity: (state)=>{
					return state.user.IsOpenCommunity
				},
				IsOpenTaoKeDistributorModel: (state)=>{
					return state.user.IsOpenTaoKeDistributorModel
				},

			}),
			...mapGetters([
				'IsDockingRoutuan'
			])
		},
		watch:{
			IsOpenCommunity(){
				this.setMenu();
			}
		},
		methods: {
			getdata: function(evt) {
				console.log(evt)
				//return evt.target.className !== 'item-box';
			},
			end(event) {
				console.log(event);
			},
			datadragEnd: function(evt) {
				// console.log('拖动前的索引：' + evt.oldIndex);
				// console.log('拖动后的索引：' + evt.newIndex);
			},
			addModule(record) {
				// console.log(record)
				this.$emit('click', record)
			},
			setMenu(){
				var list = this.$store.state.pageManageConfig.moduleList;
				
				// if (this.isGroup && !this.IsOpenCommunity){
				// 	list = list.filter(item=>{
				// 		return item.moduleType != 24 && item.moduleType != 25;
				// 	})
				// }else if (!this.isGroup){
				// 	if (!this.IsOpenCommunity){
				// 		list = list.filter(item=>{
				// 			return item.moduleType != 24 && item.moduleType != 25;
				// 		})
				// 	}else{
				// 		list = list.filter(item=>{
				// 			return item.moduleType != 25;
				// 		})
				// 	}
				// }
				let nolist = [25]
				//之前不展示25 不知道为什么
				//5.1.0如果admin没有开启淘客分销模式 不展示多多商品和多多营销
				if(!this.IsOpenTaoKeDistributorModel){
					nolist.push(32,33)
				}
				if(!this.IsDockingRoutuan){
					nolist.push(34)
				}
				list = list.filter(item=>{
					return !nolist.some(v=>v==item.moduleType)
				})
				this.menuList = list;
				// console.log(list)
			}
		},
		mounted() {
			this.setMenu();
			
			// var el = this.$refs.dragable.$children[1].$el.children[1];
			// let vm = this;
			// Sortable.create(el, {
			//     onStart: vm.startFunc,
			//     onEnd: vm.endFunc,
			//     onChoose: vm.chooseFunc
			// });
		},
	};
</script>
