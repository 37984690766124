<template>
  <div class="module" v-if="visible">
    <p class="title">
			{{name}}
		</p>
    <div v-if="App.AppId">
      <div class="flex" style="margin-bottom:20px">
        <div class="label">样式配置</div>
        <div>
          <el-radio-group v-model="data.styleType">
            <el-radio :label="0">系统默认样式</el-radio>
            <el-radio :label="1">自定义</el-radio>
          </el-radio-group>
          <div v-if="data.styleType==1">
            <el-upload class="custom-img" :action="uploadApi" :show-file-list="false" list-type="picture-card"
              :on-success="(e)=>{handleEntranceSuccess(e,'img')}" :before-upload="beforeEntranceUpload">
              <img v-if="data.img" :src="imgUrl + data.img" style="width:100%;height:100%">
              <i v-else class="el-icon-plus custom-img-icon"></i>
            </el-upload>
            <div class="counterword">图片大小请控制在1M以内；支持jpg，png，gif格式</div>
          </div>
        </div>
      </div>
      <div class="flex flex-align-center" style="margin-bottom:20px" v-if="data.styleType==0">
        <div class="label">引导语</div>
        <el-input v-model="data.guideLanguage" style="width:300px" maxlength="16" placeholder="最多输入16个字"></el-input>
      </div>
      <div class="flex" style="margin-bottom:20px">
        <div class="label">公众号二维码</div>
        <div>
          <el-upload class="qrcode-img" :action="uploadApi" :show-file-list="false" list-type="picture-card"
            :on-success="(e)=>{handleEntranceSuccess(e,'qrCode')}" :before-upload="beforeEntranceUpload">
            <img v-if="data.qrCode" :src="imgUrl + data.qrCode" style="width:100%;height:100%">
            <i v-else class="el-icon-plus custom-img-icon"></i>
          </el-upload>
          <div class="counterword">图片大小请控制在1M以内；支持jpg，png，gif格式</div>
        </div>
      </div>
      <div class="mod-footer">
        <div class="left">
          <el-checkbox v-model="data.hideSpace"><span style="font-size: 14px;color:#666">隐藏模块下方的间距</span>
          </el-checkbox>
        </div>
      </div>
    </div>
    <div v-else style="width:400px;margin:0px auto;color:#606266FF">
      商城还未绑定微信公众号，请先前往绑定，绑定完成后，可添加该模块 
      <el-button type="text" @click="$router.push({path:'/setting/setOfficialAccount'})">去绑定</el-button>
    </div>
  </div>
</template>

<script>
import config from '@/config/index';
import{
  wxPublicPlatFromInfo
} from '@/api/goods.js';
export default {
  props: {
    data: {
      type: Object,
      default: () => {
        return {}
      }
    },
    visible: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      default: ''
    }
  },
  created () {
    // console.log(this.data)
  },
  mounted () {
    this.getData()
  },
  data(){
    return {
      uploadApi: config.UPLOAD_IMG,
      imgUrl: config.IMG_BASE,
      App:{}
    }
  },
  methods:{
    async getData() {
      let result = await wxPublicPlatFromInfo()
      if(result.IsSuccess){
        this.App = result.Result
      }
    },
    beforeEntranceUpload(file) {
      const isLt1M = file.size / 1024 / 1024 < 1;
      const isJPG = file.type === 'image/jpeg';
      const isPNG = file.type === 'image/png';
      const isGIF = file.type === 'image/gif';
      
      if (!isJPG & !isPNG && !isGIF){
        this.$message.error('请上传正确的图片格式');
        return false
      }
      if (!isLt1M) {
        this.$message.error('图片大小请控制在1M以内 (含1M)');
        return false
      }
      return true;
    },
    handleEntranceSuccess(file, key){
      this.data[key] = file[0]
    },
  }
}
</script>

<style lang="less" scoped>
.module{
  width: 480px;
  padding: 20px 20px 30px;
  background: #fff;
  font-size: 14px;
  .title {
    position: relative;
    font-size: 18px;
    padding-bottom: 20px;
    margin-bottom: 25px;
    border-bottom: 1px solid #ddd;
  }
  .flex{
    display: flex;
  }
  .flex-align-center{
    align-items: center;
  }
  .label{
    font-size: 14px;
    color: #606266;
    font-weight: bold;
    line-height: 20px;
    white-space: nowrap;
    margin-right: 10px;
    width: 100px;
    text-align: right;
  }
  .custom-img{
    margin-top:20px;
    ::v-deep .el-upload{
      width: 290px;
      height: 145px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .qrcode-img{
    ::v-deep .el-upload{
      width: 80px;
      height: 80px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .counterword{
    font-size: 14px;
    font-weight: 400;
    color: #999999;
    line-height: 20px;
    margin-top: 5px;
  }
}
.mod-footer {
  margin-top: 30px;
  padding-top: 20px;
  border-top: 1px solid #ddd;

  display: flex;

  .left {
    flex: 1 1 auto;
    padding-top: 6px;
  }
}
</style>