<style scoped lang="less">
	.singleRow-module-edit {
		// position: absolute;
		// right: -530px;
		width: 480px;
		padding: 20px 20px 30px;
		background: #fff;
		font-size: 14px;

		.title {
			position: relative;
			font-size: 18px;
			padding-bottom: 20px;
			margin-bottom: 25px;
			border-bottom: 1px solid #ddd;
		}

		.icon-close {
			position: absolute;
			right: 0;
			cursor: pointer;
		}

		.tip {
			margin-bottom: 10px;
		}

		.product-list {
			.product-item {
				display: flex;
				margin-bottom: 10px;
			}

			.box {
				flex: 1 1 auto;
				background: #f5f5f5;
				height: 60px;
				padding: 0 10px;
				font-size: 14px;
				color: #666;

				display: flex;
				align-items: center;

				img {
					flex: 0 0 auto;
					display: block;
					width: 54px;
					height: 32px;
					margin-right: 10px;
				}

				.info {
					flex: 1 1 auto;
				}
			}

			.control {
				flex: 0 0 auto;
				width: 45px;
				text-align: right;
				margin-top: 12px;

				span {
					display: inline-block;
					cursor: pointer;
				}

				.edit {
					color: #1890ff
				}

				.delete {
					color: #f00000;
					margin-top: 3px;
				}
			}
		}



		.add-btn {
			width: 440px;
			text-align: center;
			border: 1px dashed #ddd;
			font-size: 16px;
			color: #666;
			height: 48px;
			line-height: 48px;
			text-align: center;
			margin-top: 10px;
			cursor: pointer;
		}

		.mod-footer {
			margin-top: 30px;
			padding-top: 20px;
			border-top: 1px solid #ddd;

			display: flex;

			.left {
				flex: 1 1 auto;
				padding-top: 6px;
			}
		}

		.choose-box {
			display: flex;
			width: 225px;
		}

		.route {
			line-height: 24px;
			flex: 1 1 auto;
			font-size: 12px;
			color: #666;
			font-family: simsun;
			margin-left: 10px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}

	.product-list {
		padding-top: 10px;
		overflow: hidden;

		.product-item {
			position: relative;
			float: left;
			width: 65px;
			height: 65px;
			margin-right: 10px;
			margin-bottom: 10px;
		}

		.img {
			position: relative;
			width: 65px;
			height: 65px;
			overflow: hidden;

			img {
				display: block;
				width: 100%;
			}
		}

		.delete {
			position: absolute;
			top: -10px;
			right: -10px;
		}

	}


	.coupon-list {
		.coupon-item {
			display: flex;
			margin-bottom: 10px;
		}

		.box {
			flex: 1 1 auto;
			background: #f5f5f5;
			height: 60px;
			padding: 0 10px;
			font-size: 14px;
			color: #666;

			display: flex;
			align-items: center;

			img {
				flex: 0 0 auto;
				display: block;
				width: 54px;
				height: 32px;
				margin-right: 10px;
			}

			.info {
				flex: 1 1 auto;
			}
		}

		.control {
			flex: 0 0 auto;
			width: 45px;
			text-align: right;

			span {
				display: inline-block;
				cursor: pointer;
			}

			.edit {
				color: #1890ff
			}

			.delete-btn {
				color: #f00000;
				margin-top: 20px;
			}
		}
	}
</style>

<template>
	<div>

		<!-- <transition name="scale"> -->
			<div class="singleRow-module-edit" v-if="visible">

				<p class="title">
					{{name}}
					<!-- <i @click="close" class="el-icon-close icon-close" style="font-size: 32px;color: #999;top:-12px"></i> -->
				</p>

				<el-form label-width="70px" size="mini">
					<el-form-item label="选择商品">
						<el-radio-group v-model="data.moduleType">
							<el-radio :label="1">商品</el-radio>
							<el-radio :label="2">优惠券</el-radio>
						</el-radio-group>
					</el-form-item>
				</el-form>

				<div v-if="data.moduleType==1">
					<draggable class="product-list" :list="data.list" :options="{animation: 300, handle:'.product-item'}">
						<transition-group name="bounce-out-left">
							<div class="product-item" :key="item.ActivityFullId" v-for="item in data.list">
								<div class="img">
									<img :src="$store.state.pageManageConfig.imghost+item.ImgUrl" />
								</div>
								<div class="delete" @click="deleteData(item)">
									<i class="el-icon-error" style="font-size: 24px;opacity: .6;"></i>
								</div>
							</div>
						</transition-group>
					</draggable>
				</div>

				<div v-if="data.moduleType==2">
					<draggable class="coupon-list" :list="data.list" :options="{animation: 300, handle:'.coupon-item'}">
						<transition-group name="bounce-out-left">
							<div class="coupon-item" :key="item.ActivityFullId" v-for="item in data.couponList">
								<div class="box">
									<img src="http://cdn.dkycn.cn/images/melyshop/page-manage-coupon-small.png" />
									<div class="info">
										<div>优惠券名称：{{item.Name}}</div>
									</div>
								</div>
								<div class="control">
									<!-- <span class="edit" @click="changeData(item)">修改</span> -->
									<span class="delete-btn" @click="deleteCouponData(item)">删除</span>
								</div>
							</div>
						</transition-group>
					</draggable>
				</div>

				<div class="add-btn" @click="showModal" v-if="data.moduleType==1">
					<i class="el-icon-plus" style="margin-right: 5px;vertical-align: -2px;font-size: 18px;"></i>添加商品
				</div>
				<div class="add-btn" @click="showCouponModal" v-if="data.moduleType==2">
					<i class="el-icon-plus" style="margin-right: 5px;vertical-align: -2px;font-size: 18px;"></i>添加优惠券
				</div>


				<div class="mod-footer">
					<div class="left">
						<el-checkbox v-model="data.hideSpace"><span style="font-size: 14px;color:#666">隐藏模块下方的间距</span></el-checkbox>
					</div>
					<!-- <div class="right">
                    <Button type="primary" @click="saveData">保存</Button>
                </div> -->
				</div>



			</div>
		<!-- </transition> -->
		<pointModuleShopList :visible="shopVisible" :selectedList="data.list" @change="dataChange" @cancel="shopVisible=false"></pointModuleShopList>
		<pointCouponModal :visible="couponVisible" :selectedList="data.couponList" @change="couponChange" @cancel="couponVisible=false"></pointCouponModal>
	</div>
</template>

<script>
	import pointModuleShopList from '../../../../components/pointModuleShopList.vue';
	import pointCouponModal from '../../../../components/pointCouponModal.vue';
	import draggable from 'vuedraggable';

	export default {
		name: 'normal-module-edit',
		components: {
			pointModuleShopList,
			pointCouponModal,
			draggable
		},
		props: {
			data: {
				type: Object,
				default: () => {
					return {}
				}
			},
			visible: {
				type: Boolean,
				default: false
			},
			name: {
				type: String,
				default: ''
			}
		},
		data() {
			return {
				shopVisible: false,
				couponVisible: false
			};
		},
		methods: {
			close() {
				this.$emit('close')
			},
			showModal(record) {
				this.shopVisible = true;
			},
			dataChange(record) {
				this.data.list = record;
			},
			deleteData(record) {

				this.data.list = this.data.list.filter(item => {
					return item.ActivityFullId !== record.ActivityFullId;
				})

			},

			showCouponModal(record) {
				this.couponVisible = true;
			},
			couponChange(record) {
				this.data.couponList = record;
			},
			deleteCouponData(record) {

				this.data.couponList = this.data.couponList.filter(item => {
					return item.ActivityFullId !== record.ActivityFullId;
				})

			},

			saveData() {
				this.$emit('submit')
			},
			guid() {
				var a = function() {
					return (65536 * (1 + Math.random()) | 0).toString(16).substring(1)
				};
				return a() + a() + "-" + a() + "-" + a() + "-" + a() + "-" + a() + a() + a()
			}

		},
		mounted() {
			// this.data.list.push({
			//         uid: this.guid(),
			//         imgUrl: '/ShopDecorate/61f677cf-ce0d-4364-b3d8-9cdeffe089aa.jpg',
			//         type: 1, //链接类型
			//         link: '', //自定义链接
			//         selectedData: {}, //弹框选中数据
			//     })
			console.log(this.data, 'point')
		},
		watch: {
			data(value) {
				//this.$set(this.data,'btnType',1)

				//this.data.btnType = 1;
				//console.log(value)
			}
		}
	};
</script>
